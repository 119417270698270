var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.alert)?_c('div',{staticClass:"rounded border flex items-center flex-col sm:flex-row",class:{
    'bg-green-400 border-green-500 text-white': _vm.alert.type == 'success',
    'bg-red-400 border-red-500 text-white': _vm.alert.type == 'error',
    'bg-yellow-500 border-yellow-600 text-white': _vm.alert.type == 'warning',
  }},[_c(_vm.icon,{tag:"component",staticClass:"m-4 rounded-full p-1 w-14 flex-shrink-0",class:{
    'bg-green-300': _vm.alert.type == 'success',
    'bg-red-300': _vm.alert.type == 'error',
    'bg-yellow-400': _vm.alert.type == 'warning',
  },attrs:{"size":56}}),_c('div',{staticClass:"p-2 py-4"},[_c('h3',{staticClass:"text-lg font-medium mb-1",domProps:{"textContent":_vm._s(_vm.alert.title)}}),_c('p',{staticClass:"text-sm",domProps:{"innerHTML":_vm._s(_vm.alert.text)}})])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }